import React, { FormEvent, useEffect, useState } from "react";
import { Alert, Button, Form, UncontrolledTooltip } from "reactstrap";
import Loader from "../../ui/Loader";
import {
    DescribeBankOfCoresResponse,
    EditBankOfCoresSublicenseRequest,
    GenerateBankOfCoresSublicenseResponse,
    ToggleBankOfCoresSublicenseRequest
} from "../../../models/bankOfCoresModel";
import { useInput } from "rooks";
import { exceptionHandler } from "../../../utils/exceptionHandlers";
import { useAuth } from "../../../hooks/useAuth";
import { Redirect } from "react-router";
import SubliceneList from "./SubliceneList";
import GeneratedSublicenseModal from "./GeneratedSublicenseModal";
import { downloadBlobAsFile } from "../../../utils/fileUtils";
import GenerateSublicenseConfirmation from "./GenerateSublicenseConfirmation";
import { messagePublisher } from "../../../utils/MessagePublisher";
import MainLicense from "./MainLicense";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCircleInfo, faWarning } from '@fortawesome/free-solid-svg-icons';
import { useServices } from "../../../hooks/useServices";
import DatePicker from "../../ui/DatePicker";
import moment from "moment";

const BankOfCoresPage = () => {
    const { userInfo } = useAuth();
    const { bankOfCoresService } = useServices();
    
    if (!userInfo.isAuthenticated) {
        return <Redirect to="/" />;
    }

    const [isLoading, setIsLoading] = useState(false);
    const [isDownloadingSublicense, setIsDownloadingSublicense] = useState(false);
    const [isEditingSublicense, setIsEditingSublicense] = useState(false);
    const [isGeneratingSublicense, setIsGeneratingSublicense] = useState(false);
    const [isCreateSublicenseConfirmationOpen, setIsCreateSublicenseConfirmationOpen] = useState(false);
    const [bankOfCoresDto, setBankOfCoresDto] = useState<DescribeBankOfCoresResponse>(null);
    const [generatedSublicenseDto, setGeneratedSublicenseDto] = useState<GenerateBankOfCoresSublicenseResponse>(null);

    const inputCoresCount = useInput(1);
    const inputSublicenseTag = useInput("");
    const inputCustomerName = useInput("");
    const [inputExpirationDate, setInputExpirationDate] = useState<Date>(null);

    const isExpired = bankOfCoresDto?.expirationDate
        ? moment(bankOfCoresDto.expirationDate).isBefore(moment())
        : false;

    const describeBankOfCores = async (event?: FormEvent<HTMLFormElement>) => {
        if (event) {
            event.preventDefault();
        }

        setIsLoading(true);
        setBankOfCoresDto(null);

        try {
            const result = await bankOfCoresService.describeBankOfCores();

            setBankOfCoresDto(result);

        } catch (e) {
            exceptionHandler(e, "Failed to describe bank of cores.")
        } finally {
            setIsLoading(false);
        }
    }

    const generateSublicense = async () => {
        setIsGeneratingSublicense(true);

        try {
            const result = await bankOfCoresService.generateSublicense({
                cores: inputCoresCount.value,
                tag: inputSublicenseTag.value,
                customerName: inputCustomerName.value,
                expirationDate: inputExpirationDate ? moment(inputExpirationDate).format("YYYY-MM-DD") : null
            });
            setGeneratedSublicenseDto(result);

        } catch (e) {
            exceptionHandler(e, "Failed to generate sublicense.")
        } finally {
            setIsGeneratingSublicense(false);
            setIsCreateSublicenseConfirmationOpen(false);
        }
    }

    const downloadSublicense = async (sublicenseId: string, sublicenseTag: string) => {
        setIsDownloadingSublicense(true);
        
        try {
            const result = await bankOfCoresService.downloadSublicense({
                sublicenseId
            });

            const jsonSublicense = JSON.stringify(JSON.parse(result.sublicense), null, 2);
            const blob = new Blob([jsonSublicense], { type: "application/json" });

            const fileName = userInfo.bankOfCoresKey + "_" + sublicenseTag;
            downloadBlobAsFile(fileName, blob);
            
            await describeBankOfCores();
            
        } catch (e) {
            exceptionHandler(e, "Failed to download sublicense.")
        } finally {
            setIsDownloadingSublicense(false);
        }
    }

    const editSublicense = async (editSublicenseRequest: Omit<EditBankOfCoresSublicenseRequest, "bankOfCoresKey">) => {
        setIsEditingSublicense(true);

        try {
            await bankOfCoresService.editSublicense(editSublicenseRequest);
            messagePublisher.reportSuccess("The sublicense has been edited successfully.");
            
        } catch (e) {
            exceptionHandler(e, "Failed to edit sublicense.")
        } finally {
            setIsEditingSublicense(false);
            await describeBankOfCores();
        }
    }

    const toggleSublicense = async (toggleSublicenseRequest: ToggleBankOfCoresSublicenseRequest) => {
        try {
            await bankOfCoresService.toggleSublicense(toggleSublicenseRequest);
            messagePublisher.reportSuccess("The sublicense has been toggled successfully.");

        } catch (e) {
            exceptionHandler(e, "Failed to toggle sublicense.")
        } finally {
            await describeBankOfCores();
        }
    }

    const showCreateSublicenseConfirmation = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsCreateSublicenseConfirmationOpen(true);
    }
    
    useEffect(() => {
        (async () => {
            await describeBankOfCores();
        })();
    }, []);

    return (
        <div className="bank-of-cores-page">
            <h1>Manage ISV Bank Of Cores</h1>
            { isLoading && <Loader /> }
            { bankOfCoresDto && (
                <>
                    <MainLicense bankOfCoresDto={bankOfCoresDto} bankOfCoresKey={userInfo.bankOfCoresKey} isExpired={isExpired} />
                    {!isExpired && (
                        <div className="generate-sublicense-panel">
                            <h3>Generate new sublicense</h3>
                            <Form className="generate-sublicense-form" onSubmit={showCreateSublicenseConfirmation}>
                                <div>
                                    <label className="d-block">Cores count</label>
                                    <input
                                        {...inputCoresCount}
                                        className="cores-count-input"
                                        type="number"
                                        disabled={isLoading}
                                        placeholder="Cores"
                                        min={1}
                                        max={bankOfCoresDto.remainingCores}
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="d-block">Sublicense tag</label>
                                    <input
                                        {...inputSublicenseTag}
                                        type="text"
                                        disabled={isLoading}
                                        placeholder="Tag"
                                        maxLength={50}
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="d-block">Customer name</label>
                                    <input
                                        {...inputCustomerName}
                                        type="text"
                                        disabled={isLoading}
                                        placeholder="Customer name"
                                        maxLength={50}
                                    />
                                </div>
                                <div>
                                    <label className="d-block">
                                        <span>
                                            <FontAwesomeIcon icon={faCircleInfo} id="expiration-date-info" />
                                            {" "}
                                            Expiration date
                                        </span>
                                    </label>
                                    <UncontrolledTooltip target="expiration-date-info">
                                        If you leave this field empty, it will be set to the expiration date of the main license.
                                    </UncontrolledTooltip>
                                    <DatePicker
                                        selected={inputExpirationDate}
                                        onChange={(x: Date) => setInputExpirationDate(x)}
                                        showYearDropdown
                                        showMonthDropdown
                                        minDate={new Date()}
                                        maxDate={new Date(bankOfCoresDto.expirationDate)}
                                        disabled={isLoading}
                                        placeholderText="Expiration date"
                                    />
                                </div>
                                <div>
                                    <Button
                                        color="primary"
                                        type="submit"
                                        disabled={isLoading}
                                    >
                                        <FontAwesomeIcon icon={faPlus} className="margin-right-xxs" /> Generate
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    )}
                    <GenerateSublicenseConfirmation
                        cancel={()=> setIsCreateSublicenseConfirmationOpen(false)}
                        show={isCreateSublicenseConfirmationOpen}
                        isGeneratingSublicense={isGeneratingSublicense}
                        confirm={generateSublicense}
                        coreCount={inputCoresCount.value}
                    />
                    <GeneratedSublicenseModal
                        sublicenseTag={inputSublicenseTag.value}
                        generatedSublicenseDto={generatedSublicenseDto}
                        bankOfCoresKey={userInfo.bankOfCoresKey}
                        closeModal={async () => {
                            setGeneratedSublicenseDto(null);
                            await describeBankOfCores();
                        }}
                    />
                    <SubliceneList
                        sublicenses={bankOfCoresDto.sublicenses}
                        downloadSublicense={downloadSublicense}
                        editSublicense={editSublicense}
                        toggleSublicense={toggleSublicense}
                        bankOfCoresKey={userInfo.bankOfCoresKey}
                        remainingCores={bankOfCoresDto.remainingCores}
                        memoryRatio={bankOfCoresDto.memoryRatio}
                        mainLicenseExpirationDate={bankOfCoresDto.expirationDate}
                        disabled={isLoading || isDownloadingSublicense || isEditingSublicense}
                        isExpired={isExpired}
                    />
                </>
            )}
        </div>
    );
}

export default BankOfCoresPage;
